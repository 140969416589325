import React from 'react'
import { observer, inject } from "mobx-react"
import { useStaticQuery, graphql } from "gatsby"
import VideoPlayer from "./videoPlayer"
//import { Player, ControlBar, ClosedCaptionButton, BigPlayButton } from 'video-react';
//import videojs from 'video.js'
                            /*videoH264(
                            screenshots: "23%"
                            ) {
                                path
                                screenshots {
                                    path
                                }

                            }
                            videoVP9(
                            screenshots: "23%"
                            ) {
                                path
                                screenshots {
                                    path
                                }

                            }*/

const VideoView = inject(`uiStore`)(
    observer(({ uiStore }) => {
        const data = useStaticQuery(graphql`
            {
                allFile(filter: { extension: { regex: "/mp4|m4a|webm|ogg|mov|vtt/"}}) {
                    edges {
                        node {
                            id
                            extension
                            relativePath
                            relativeDirectory
                            base
                            publicURL
                        }
                    }
                }
                h264: allFile(filter: { extension: { regex: "/mp4|webm|mov/"}}) {
                    edges {
                        node {
                            id
                            videoProfile(profile: "customH264") {
                                path
                            }
                        }
                    }
                }
                vp9: allFile(filter: { extension: { regex: "/mp4|webm|mov/"}}) {
                    edges {
                        node {
                            id
                            base
                            videoProfile(profile: "customVP9") {
                                path
                            }
                        }
                    }
                }
            }
        `)

        let lastImage
	const { allFile, h264, vp9 } = data
        let path, dir, index, vp9Path, h264Path
	// selection and focus handling //////////////////////////////////////////////////////////////////////////////////////////
        const { fileFocus, fileSelection, directoryFocus, directorySelection } = uiStore
        if (fileFocus !== null) {
            path = fileFocus
            dir = directoryFocus
        }
        else if (fileSelection !== null) {
            path = fileSelection
            dir = directorySelection
        }
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
        let img = allFile.edges.find((file, i)=> {
            if (file.node.relativePath === path){


		// debugger
		/*return {
		    f: file.node,
		    webm: vp[0].node.videoProfile,
		    mp4: h[0].node.videoProfile
		}*/
		
                return file.node
            }
            else
                return null
        })






		const vp = vp9.edges.filter(obj => {
		    return obj.node.id === img.node.id
		})

		const h = h264.edges.filter(obj => {
		    return obj.node.id === img.node.id
		})
		const files = {
		    webm: vp[0].node.videoProfile,
		    mp4: h[0].node.videoProfile
		}
	
        let caption = allFile.edges.find((file, index) => {
            if(file.node.relativeDirectory === dir && file.node.extension === 'vtt'){
                return file.node
            }
            else
                return null

        })
        let image = null
        //const container = document.getElementsByClassName('2')[0].children[1]
        //console.log(container.offsetWidth, container.offsetHeight)
        //const containerSize = {width: container.offsetWidth, height: container.offsetHeight}
        if(typeof img !="undefined" && img.node.extension !== "md" && image !== lastImage) {
            // let posterPath = img.node.videoVP9.screenshots[0].path
            if(img.node.relativePath === uiStore.mainVideo) {
                let captionPath
                if(typeof caption!== 'undefined')
                    captionPath = caption.node.publicURL
                else
                    captionPath = null
                return(
                    <VideoPlayer 
                        sources={[{src: files.webm.path, type: 'video/webm'}, {src: files.mp4.path, type: 'video/mp4'}]}
                        controls={true}
                        subtitles={captionPath}
                        fill={true}
                        fluid={false}
                        preload= {'auto'}
                        loop={true}
                        relativeName={img.node.relativePath}
                        main={true}
                        autoplay={false}
                    />
                )
            }
            else
                //    <video key={img.node.videoVP9.path} poster={posterPath} loop autoPlay><source src={img.node.videoVP9.path} type={"video/webm"} /><source src={img.node.videoH264.path} type={"video/mp4"} /></video>
                return(
                    <VideoPlayer 
                        sources={[{src: files.webm.path, type: 'video/webm'}, {src: files.mp4.path, type: 'o/mp4'}]}
                        controls={false}
                        autoplay={true}
                        fluid={false}
                        fill={true}
                        preload= {'auto'}
                        loop= {true}
                        relativeName={img.node.relativePath}
                        main={false}
                    />
                )
        }
    })
)
export default VideoView
