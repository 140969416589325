import React, { Component } from 'react'
import videojs from 'video.js'
import panAndZoomHoc from 'react-pan-and-zoom-hoc'
import Measure from 'react-measure'
import { observer, inject } from "mobx-react"

const InteractiveDiv = panAndZoomHoc('div');

const VideoPlayer = inject('uiStore')(class VideoPlayer extends Component {
// class VideoPlayer extends Component {
// export default inject("uiStore")observer(class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        //console.log(props)
        this.state = {
            newProps: {},
            x: 0.5,
            y: 0.5,
            scale: 1,
            containerWidth: 0,
            containerHeight: 0,
            videoWidth: 0,
            videoHeight: 0,
        }
    }

    componentDidMount() {
        let { uiStore } = this.props
        this.setState({ newProps: this.props })
        this.player = videojs(this.videoNode, {...this.props, fill: true}, function onPlayerReady() {
        })
        
                               
        const video = document.getElementsByClassName('viewer')[0]
        if (this.state.containerWidth !== video.offsetWidth || this.state.containerHeight !== video.offsetHeight) {
            this.setState({
                containerWidth: video.offsetWidth,
                containerHeight: video.offsetHeight
            })
        }

        this.player.on('playing', () => {
            

            console.log(this.state.newProps.autoplay)
            if (!this.state.newProps.autoplay) {
                console.log("lock")
                // console.log(uiStore.mainVidPlaying)
                uiStore.setMainVidPlaying(true)
            }
            // check if video is even main
            
            
//            console.log("playing")
         })
        
        this.player.on('pause', () => {
            uiStore.setMainVidPlaying(false)
            // console.log("pause")
        })        
        
        this.player.on('loadedmetadata', () => {
            const videoWidth = this.player.videoWidth()
            const videoHeight = this.player.videoHeight()
            const aspectRatio = videoWidth / videoHeight
            // possible containerSizes for guessing kinda brute forcing but its only two vals so its ok i guesss
            const containerWidth = this.state.containerHeight * aspectRatio
            const containerHeight = this.state.containerWidth / aspectRatio
            ////////////////////////////////////////////////////////////////////////////////////////////////////
            if (containerWidth <= this.state.containerWidth) {
                this.setState({
                    videoWidth: containerWidth,
                    videoHeight: this.state.containerHeight,
                    videoWidth: videoWidth,
                    videoHeight: videoHeight,
                    aspectRatio: aspectRatio
                })
            }
            else if (containerHeight <= this.state.containerHeight) {
                this.setState({
                    videoWidth: this.state.containerWidth,
                    videoHeight: containerHeight,
                    videoWidth: videoWidth,
                    videoHeight: videoHeight,
                    aspectRatio: aspectRatio
                })
            }
            this.player.controls(this.state.newProps.controls)
            const options = {
                "src": this.props.subtitles,
                "label": "beschreibung",
                "mode": "showing"
            }
            if(this.props.subtitles !== null)
                this.player.addRemoteTextTrack(options, false)
            //this.player.addRemoteTextTrack({default: true, kind: "description", srclang: "de", label: "Beschreibung", src: this.props.subtitles}, false)
            //console.log("ffooo")
            /*<track 
                src={this.props.subtitles} 
                default
                kind="description" srcLang="de" label="Beschreibung"/>

        </video>*/
        })
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
            let { uiStore } = this.props
            uiStore.setMainVidPlaying(false)
        }
    }

    componentDidUpdate(prevProps) {
        const {uiStore} = this.props
        if (this.player) {
            if (prevProps.relativeName !== this.state.newProps.relativeName) {
                this.player.src(this.state.newProps.sources)
                this.player.autoplay(this.state.newProps.autoplay)
                uiStore.setMainVidPlaying(false)
            }
            else if (prevProps.relativeName === this.state.newProps.relativeName && prevProps.main !== this.state.newProps.main) {
                this.player.autoplay(this.state.newProps.autoplay)
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.relativeName !== prevState?.relativeName) {
            return({ 
                newProps: nextProps
            })
        }
        else return null
    }

    handlePanZoom(x, y, scale) {
        if(!this.props.main)
            this.setState({ x, y, scale })
    }

    handlePanMove(x, y) {
        if(!this.props.main)
            this.setState({ x, y })
    }

    transformPoint({ x, y }) {
        return {
            x: 0.5 + this.state.scale * (x - this.state.x),
            y: 0.5 + this.state.scale * (y - this.state.y)
        }
    }

    render() {
        const { x, y, scale, containerWidth, containerHeight, videoWidth, videoHeight } = this.state
        const p1 = this.transformPoint({x: 0.5, y: 0.5})
        const video = document.getElementsByClassName('viewer')[0]
        /*const containerWidth = video.offsetWidth
        const containerHeight = video.offsetHeight*/
        /*if (this.props.main)
            return (
                <div data-vjs-player>
                    <video ref={ (node) => this.videoNode = node } className="video-js vjs-theme-sea" />
                </div>
            )
        else*/
            return (
                <Measure
                    bounds
                    onResize={contenRect => {
                        const { width, height } = contenRect.bounds
                        this.setState({
                            containerWidth: width,
                            containerHeight: height
                        })
                    }}
                >
                    {({ measureRef }) => (
                        <div ref={measureRef} style={{ height: '100%' }}>
                            <InteractiveDiv
                                x={x}
                                y={y}
                                scale={scale}
                                scaleFactor={Math.sqrt(2)}
                                minScale={0.25}
                                maxScale={20}
                                onPanAndZoom={(x, y, scale) => this.handlePanZoom(x, y, scale)} 
                                ignorePanOutside
                                onPanMove={(x, y) => this.handlePanMove(x, y)}
                                style={{ width: '100%', height: '100%', boxSizing: 'border-box' }}
                            >
                                <div style={{ position: 'absolute', width: containerWidth * this.state.scale, height: containerHeight * this.state.scale, transform:`translate(${p1.x * containerWidth}px, ${p1.y * containerHeight}px) translate(${-(containerWidth/2) * scale}px, ${-(containerHeight/2) * scale}px)` }}>
                                    <div data-vjs-player>
                                        <video 
                                            ref={ (node) => this.videoNode = node } 
                                            className="video-js vjs-theme-sea" />
                                    </div>
                                </div>
                            </InteractiveDiv>
                        </div>               
                    )}
                </Measure>
            )
    }

})

export default VideoPlayer
