import React, {Component } from "react"
import {
    Mosaic,
    MosaicWindow,
    MosaicZeroState,
    ExpandButton
} from "react-mosaic-component";
import { StaticQuery, graphql } from "gatsby"
import FileTree from "./filetree"
import MediaWindow from "./MediaWindow"
import DescriptionWindow from "./DescriptionWindow"
import MetadataWindow from "./MetadataWindow"
// import { observer, inject } from "mobx-react"
import MediaQuery from 'react-responsive'

export default class TileManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blocked: false,
            currentNode: {
                direction: 'row',
                second: 1,
                first: {
                    direction: 'column',
                    first: 2,
                    second: {
                        direction: 'row',
                        first: 4,
                        second: 3,
                        splitPercentage: 40
                    },
                    splitPercentage: 66
                },
                splitPercentage: 66
            },
        }
    }

    onChange = (node) => {
        if(this.state.blocked)
            this.setState({ currentNode: node })
        else
            this.setState({
                currentNode: node,
                blocked: true
            })
    }

    createNode = () => {
    }

    onRelease = (currentNode) => {
        //console.log('Mosaic.onRelease():', currentNode);
        this.setState({ blocked: false })
        //console.log(this.state.blocked)
    }

    render() {
        if (typeof window !== `undefined`) {
            return (
                <StaticQuery
                    query={graphql`{
                        allFile(filter: {relativeDirectory: {regex: "/content/"}}, sort: {order: ASC, fields: base}) {
                            edges {
                                node {
                                    absolutePath
                                    relativeDirectory
                                    base
                                    relativePath
                                    extension
                                }
                            }
                        }
                    }
                    `}
                    render={data => {
                        let tree = new Array(data.allFile.edges.length)
                        let extensions = new Array(data.allFile.edges.length)
                        data.allFile.edges.forEach((item, index) => {
                            const relativePath = item.node.relativePath
                            const extension = item.node.extension
                            tree[index] = relativePath
                            extensions[index] = extension
                        })
                        //if(!this.props.isWider || this.props.isHigher)
                        if(!this.props.isWider && !this.props.isHigher)
                            return <Mosaic
                                renderTile={(count, path) => {
                                    let title = ""
                                    if (count === 1)
                                        title = "INDEX"
                                    else if(count === 2)
                                        title = "MEDIUM"
                                    else if(count === 3)
                                        title = "BESCHREIBUNG"
                                    else if(count === 4)
                                        title = "METADATEN"
                                    //toolbarControls={[<ExpandButton key={count}/>]}
                                    return (
                                        <MosaicWindow
                                            className={title}
                                            title={`${title}`}
                                            createNode={this.createNode}
                                            path={path}
                                            toolbarControls={[]}
                                        >
                                            { count === 1 && <div className="tree"><FileTree props={data.allFile.edges} blocked={this.state.blocked } /></div>}
                                            { count === 2 && <div className="viewer"><MediaWindow /></div>}
                                            { count === 3 && <div className="description"><DescriptionWindow /></div>}
                                            { count === 4 && <div className="metadata"><MetadataWindow /></div>}
                                    </MosaicWindow>
                                    )
                                }}
                                zeroStateView={<MosaicZeroState createNode={this.createNode} />}
                                value={this.state.currentNode}
                                onChange={this.onChange}
                                onRelease={this.onRelease}
                            />
                            //else if(this.props.isWider || this.props.isHigher)
                            else 
                            return <div className="mosaic-blueprint-theme mosaic mosaic-drop-target">
                                <div className="mosaic-root">
                                    <div className="mosaic-tile" style={{top: "0%", right: "0%", left: "0%" }}>
                                        <div className="mosaic-window">
                                            <div className="mosaic-window-toolbar">
                                                <div className="mosaic-window-title">
                                                    HINWEIS
                                                </div>
                                            </div>
                                            <div className="mosaic-window-body">
                                                <div className="description">
                                                    <p>
                                                        Diese Seite biete die beste Nutzererfahrung, wenn der Bidlschirm größer ist. <br/>
                                                        Bitte vergrößern Sie das Browser-Fenster oder wechseln Sie bitte das Endgerät.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    }
                >
                </StaticQuery>
            )
        }
            else
                return null
    }
}
