import React, { Component } from 'react'
import panAndZoomHoc from 'react-pan-and-zoom-hoc'
import Img from "gatsby-image"
import Measure from 'react-measure'

const InteractiveDiv = panAndZoomHoc('div');
export default class ImagePanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            x: 0.5,
            y: 0.5,
            scale: 1,
            containerWidth: 0,
            containerHeight: 0,
        }
    }

    handlePanZoom(x, y, scale) {
        this.setState({ x, y, scale })
    }

    handlePanMove(x, y) {
        this.setState({ x, y })
    }

    transformPoint({ x, y }) {
        return {
            x: 0.5 + this.state.scale * (x - this.state.x),
            y: 0.5 + this.state.scale * (y - this.state.y)
        }
    }

    render() {
        //const {x, y, scale} = this.state;
        //const { x, y, scale, containerWidth, containerHeight } = this.state
        //const { aspectRatio } = this.props.fluid
        //const p1 = this.transformPoint({x: 0.5, y: 0.5})
        //const containerWidth = this.props.container.children[1].offsetWidth
        //const containerHeight = this.props.container.children[1].offsetHeight
        //const imgHeight = containerWidth / aspectRatio
        //let imgWidth = containerWidth
        //if (imgHeight > containerHeight)
        //    imgWidth = containerHeight * aspectRatio 
        //    //console.log("muss resied werden")
        //if(containerWidth == 0 || containerHeight == 0 ) {
        //    this.forceUpdate()
        //}
                            //<div style={{ position: 'absolute', width: imgWidth * this.state.scale, height: containerHeight * this.state.scale, transform:`translate(${p1.x * containerWidth}px, ${p1.y * containerHeight}px) translate(${-(imgWidth/2) * scale}px, ${-(containerHeight/2) * scale}px)` }}>
        return (
            <Measure
                bounds
            >
                {(props) => {
                    const { x, y, scale} = this.state
                    const { aspectRatio } = this.props.fluid
                    const p1 = this.transformPoint({x: 0.5, y: 0.5})
                    const { contentRect, measureRef } = props
                    const { width, height } = contentRect.bounds
                    const imgHeight = width / aspectRatio
                    let imgWidth = width
                    if (imgHeight > height)
                        imgWidth = height * aspectRatio 
                        //console.log("muss resied werden")
                    //if(width == 0 || height == 0 ) {
                    //    this.forceUpdate()
                    //}
                    const translateX = Math.round(p1.x * width)
                    let translateY = Math.round(p1.y * height)
                    const centerX = Math.round((imgWidth/2) * scale)
                    const centerY = Math.round((height/2) * scale)
                    const imgW = Math.round(imgWidth * this.state.scale)
                    let imgH = Math.round(imgHeight * this.state.scale) +1
                    if (imgH < height) {
                        const padding = (height - imgH) / 2
                        translateY += padding

                    }
                    /*const translateX = (p1.x * width)
                    const translateY = (p1.y * height)
                    const centerX = ((imgWidth/2) * scale)
                    const centerY = ((height/2) * scale)
                    const imgW = (imgWidth * this.state.scale)
                    const imgH = (imgHeight * this.state.scale)*/
                    //console.log('/////////////////////')
                    //console.log(width, height)
                    //console.log('/////////////////////')

                    return <div ref={measureRef} style={{ width: '100%', height: '100%' }}>
                        <InteractiveDiv
                            x={x}
                            y={y}
                            scale={scale}
                            scaleFactor={Math.sqrt(2)}
                            minScale={0.25}
                            maxScale={20}
                            onPanAndZoom={(x, y, scale) => this.handlePanZoom(x, y, scale)} 
                            ignorePanOutside
                            onPanMove={(x, y) => this.handlePanMove(x, y)}
                            style={{ width: '100%', height: '100%', boxSizing: 'border-box' }}
                        >
                            <div style={{ position: 'absolute', width: imgW, height: 'auto', transform:`translate(${translateX}px, ${translateY}px) translate(${-(centerX)}px, ${-(centerY)}px)` }}>
                            <Img
                                fluid={this.props.fluid}
                            />
                        </div>
                        </InteractiveDiv>
                    </div>
                }}
            </Measure>
        )
        /*return <InteractiveDiv
            x={x}
            y={y}
            scale={scale}
            scaleFactor={Math.sqrt(2)}
            minScale={0.25}
            maxScale={20}
            onPanAndZoom={(x, y, scale) => this.handlePanZoom(x, y, scale)} 
            ignorePanOutside
            onPanMove={(x, y) => this.handlePanMove(x, y)}
            style={{ width: containerWidth, height: containerHeight, boxSizing: 'border-box' }}
        >
            <div style={{ position: 'absolute', width: imgWidth * this.state.scale, height: containerHeight * this.state.scale, transform:`translate(${p1.x * containerWidth}px, ${p1.y * containerHeight}px) translate(${-(imgWidth/2) * scale}px, ${-(containerHeight/2) * scale}px)` }}>
                <Img
                    fluid={this.props.fluid}
                />
            </div>
        </InteractiveDiv>;*/
    }
}
