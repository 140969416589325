import React, { useState, useEffect, useRef } from 'react'
import { observer, inject } from "mobx-react"
import { useStaticQuery, graphql } from "gatsby"
import Measure from 'react-measure'
import Hyphenated from 'react-hyphen'
import de from 'hyphenated-de'

const MetadataWindow = inject(`uiStore`)(
    observer(({ uiStore }) => {
         const data = useStaticQuery(graphql`
            {
                allFile(filter: {extension: {eq: "md"}}) {
                    edges {
                        node {
                            relativePath
                            relativeDirectory
                            childMarkdownRemark {
                                frontmatter {
                                    metadata {
                                        projecttitle
                                        datum
                                        medium
                                        with
                                        for
                                        seminar
                                        supervisor
                                        tools
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `)
        const { allFile } = data
        const MetadataList = (props) => {
            const { metadata, columns } = props
            let list 
            if (typeof metadata !=='undefined' && metadata !== null) {
                list = Object.keys(metadata).map((obj, i) => {
                    let firstRow = null
                    switch(obj) {
                        case "projecttitle":
                            firstRow = "Titel"
                            break
                        case "datum":
                            firstRow = "Datum"
                            break
                        case "medium":
                            firstRow = "Medium"
                            break
                        case "with":
                            firstRow = "Zusammen mit"
                            break
                        case "for":
                            firstRow = "Für"
                            break
                        case "seminar":
                            firstRow = "Seminar"
                            break
                        case "supervisor":
                            firstRow = "Betreuung"
                            break
                        //case "tags":
                            //firstRow = "Tags"
                            //break
                        case "tools":
                            firstRow = "Werkzeuge"
                            break
                        default:
                            firstRow = null
                            break
                    }
                    if(metadata[obj] !== null) {
                        let secondRowVal
                        if(typeof metadata[obj] === 'object')
                            secondRowVal = metadata[obj].join(', ')
                        else
                            secondRowVal = metadata[obj]
                        /*return(
                            <tr>
                                <td className="first-row">
                                    {firstRow}
                                </td>
                                <td className="second-row">
                                    {secondRowVal}
                                </td>
                                
                            </tr>
                        )*/
                        let c
                        if (columns)
                            c = "pure-u-12-24"
                        else
                            c = "pure-u-24-24"
			    //<Hyphenated language={de}>o
			//</Hyphenated>
                        return (
                            <>
                                <div className={c} style={{ paddingBottom: '0.25em' }}>
                                    {firstRow}:
                                </div>
                                <div className={c} style={{ paddingBottom: '1.25em' }}>
				<Hyphenated language={de}>
                                    {secondRowVal}
			    </Hyphenated>
                                </div>
                            </>
                        )

                    }
                })
            }
            else 
                list = null
            return(
                <div className="pure-g">
                    {list}
                </div>
            )
        }
        let path
        let test = null
        let date = null
        if (uiStore.directoryFocus === null) {
            path = uiStore.directorySelection 
        }
        //else it should equal the current focused path
        else if (uiStore.directoryFocus !== null) {
            path = uiStore.directoryFocus 
        }
        let mdFile = allFile.edges.find((file, index)=> {
            if (file.node.relativeDirectory === path) {
                return file.node
            }
            else 
                return null
        })

/*        if (typeof mdFile !="undefined" && mdFile !== null) {
            if (typeof mdFile.node.childMarkdownRemark.frontmatter.metadata !== 'undefined') {
                let { tools, datum } = mdFile.node.childMarkdownRemark.frontmatter.metadata
                test = tools
                date = datum
            }

        } */
        if(mdFile?.node)
            return(
                <Measure
                    bounds
                >
                    {(props) => {
                        const { measureRef, contentRect } = props
                        let columns = true
                        if (contentRect.bounds.width > 240)
                            columns = true

                        else if (contentRect.bounds.width <= 240)
                            columns = false
                            return (
                                <div ref={measureRef}>
                                    <MetadataList 
                                        metadata={mdFile.node.childMarkdownRemark.frontmatter.metadata}
                                        columns={columns}
                                    />
                                </div>
                            )
                    }}
                </Measure>
            )
            
    })
)
export default MetadataWindow
