const sort = {
    //customOrder:["test", "firstProjectEver", "captionVideos", "sideprojects", "lastProjectEver", "test2", "test2_", "impressum", "datenschutz", "cv", "test0"]
    //customOrder:["videos", "05", "sideprojects", "03"]
    //customOrder: ["dummy", "project02", "bachelor"]
    customOrder: [
	"master",
	"iconlab",
	//okinesio ??
	"bachelor",
	"forum-interaktiv",
	"experimente",
	"motion_constellation",
	"aboutme",
	"impressum",
	"datenschutz"
    ]
}

export default sort
