import React, { useEffect, useState } from 'react'
import { observer, inject } from "mobx-react"
import { useStaticQuery, graphql } from "gatsby"
import Measure from 'react-measure'
import Hyphenated from 'react-hyphen'
import de from 'hyphenated-de'

const DescriptionWindow =inject('uiStore')(
    observer(({ uiStore }) => {
         const data = useStaticQuery(graphql`
                {
                allFile(filter: {extension: {eq: "md"}}) {
                    edges {
                        node {
                            childMarkdownRemark {
                                fileAbsolutePath
                                frontmatter {
                                    title
                                    start_image {
                                        relativePath
                                        extension
                                    }
                                    main_video {
                                        relativePath
                                    }
                                }
                                rawMarkdownBody
                                html
                            }
                            relativePath
                            relativeDirectory
                        }
                    }
                }
                }
            `)
        const { allFile } = data
        let path
        const [content, setContent] = useState(null)
        //if nothing is focused the path should equal the the last clicked selection

        if(uiStore.directoryFocus === null) {
            path = uiStore.directorySelection 
        }
        //else it should equal the current focused path
        else if(uiStore.directoryFocus !== null) {
            path = uiStore.directoryFocus 
        }
        let mdFile = allFile.edges.find((file, index)=> {
            if(file.node.relativeDirectory === path){
                return file.node
            }
            else 
                return null
        })
        let title
        //let content
        useEffect(() => {

            if(typeof mdFile !="undefined" && mdFile !== null) {
                let { html, frontmatter } = mdFile.node.childMarkdownRemark
                //console.log(html)
                setContent(html)
                //content = html
                //console.log(content)
                //title = frontmatter.title
                //console.log(frontmatter.start_image)
                if (typeof frontmatter.start_image !== "undefined" && frontmatter.start_image !== null) {
                    uiStore.setStartImage(frontmatter.start_image.relativePath, frontmatter.start_image.extension)
                }
                else if (frontmatter.start_image === null) {
                    uiStore.setStartImage("", null, null)
                }

                if(typeof frontmatter.main_video !== "undefined" && frontmatter.main_video !== null) {
                    uiStore.setMainVideo(frontmatter.main_video.relativePath)
                }
            }
	    else {
		setContent(null)
	    }
        })
	    //<Hyphenated language={de}>
	    //</Hyphenated>
        return(
            <Measure
                bounds
            >
                {(props) => {
                    const { measureRef, contentRect } = props
                    if (contentRect.bounds.width > 768)
                        return (
                            <div className="pure-g" ref={measureRef}>
                                <div className="pure-u-3-4" >
				<div dangerouslySetInnerHTML={{__html: content}} />
                                </div>
                            </div>
                        )
                    else if(content !== null) {
			return (
                            <div className="pure-g" ref={measureRef}>
                                <div className="pure-u-24-24" >
				
                                <div dangerouslySetInnerHTML={{__html: content}} />
				
                                </div>
                            </div>
                        )
		    }
		    else
			return null
		    
                        

                }}
            </Measure>
        )
    })
)
//<div className="text" dangerouslySetInnerHTML={{__html: content}} ></div>
export default DescriptionWindow
