import React from 'react'
import { inject } from "mobx-react"
import TreeView, { flattenTree } from "react-accessible-treeview";
import { navigate, useLocation  } from "@reach/router"
import { FiFolder, FiFileText, FiImage, FiFilm } from 'react-icons/fi'
import sort from "../config/customOrder"

//compare function so it can be decided if tree has to rerender
const compare = (prev, next) => {
    if (prev.blocked === next.blocked) {
        //console.log('same')
        return true
    }
    else {
        //console.log('not same')
        return false
    }
    //console.log(prev.blocked, next.blocked)
    //compare that shit for real
}

const FileTree = inject('uiStore')(({blocked, props, uiStore}) => {
    let paths = []
    props.forEach((item, index) => {
        const relativePath = item.node.relativePath
        paths[index] = relativePath
        //console.log(item.node.relativePath)
    })

    const urlLocation = useLocation()
    //const paths = props[0]
    //const extensions = props[1]
    let result = []
    let level = {result}
    const getParams = (location) => {
        const searchParams = new URLSearchParams(location.search)
        return {
            path: searchParams.get('path') || null,
            file: searchParams.get('file') || null
        }
    }
    const params = getParams(urlLocation);

    // implementation of algorithm for generating path tree from array of substrings
    // https://stackoverflow.com/questions/57344694/create-a-tree-from-a-list-of-strings-containing-paths-of-files-javascript
    paths.forEach((path, index)=> {
        path.split('/').reduce((r, name, i, a) => {
            //console.log(path)
            //console.log(extensions[index])
            if(!r[name]) {
                r[name] = {result: []}
                //if(props[index].node.extension !== 'vtt')
                    r.result.push({
                        name, 
                        children: r[name].result, 
                        last: false, 
                        parentIsLast: true, 
                        grandParentIsLast: false, 
                        isDir: false, 
                        relativeDir: props[index].node.relativeDirectory,
                        relativePath: props[index].node.relativePath,
                        ext: props[index].node.extension,
                    })
            }
            return r[name]
        }, level)
    })
    result[0].last = true
    result[0].relativeDir = ""
    result[0].relativePath = ""
    if(result[0].children.length > 0) {
        result[0].isDir = true
        result[0].ext = null
        result[0].parentIsLast = true
    }

    // custom ordering from json file
    const orderToChange = result[0].children
    const { customOrder } = sort
    let projects = []
    customOrder.forEach((el, index) => {
        let foundEl = orderToChange.find((directory)=> {
            if(directory.name === el){
                return directory
            }
            else
                return null
        })
        projects.push(foundEl)
    })

    // describing data itself for later rendering without lookup
    projects.forEach((project, index) => {
        if(index !== projects.length - 1) {
            project.children.forEach((child) => {
                child.parentIsLast = false
            })
            project.last = false
        }
        else if(index === projects.length -1) {
            project.last = true
            // check if there are subdirectories -> subprojects
            // so they can be flagged for proper tree styling later on
            const nestedProjects = project.children
            nestedProjects.forEach((nestedChild) => {
                if(nestedChild.children.length > 0) {
                    nestedChild.children.forEach(file => {
                        file.grandParentIsLast = true
                    })
                }
            })
            
            // also flag the the children with parentIsLast
        }
        if(project.children.length > 0) {
            project.isDir = true
            project.ext = null
            project.relativePath = null
        }
        // flag the last child as last element
        const lastProjectIndex = project.children.length - 1
        project.children[lastProjectIndex].last = true
        const subProjects = project.children
        subProjects.forEach((subProject, subProjectIndex) => {
            //console.log(subProject)
            const subProjectSize = subProject.children.length - 1
            const lastSubProjectIndex = subProject.children.length - 1
            //console.log(subProject.children)
            //console.log(subProjectSize)
            if(lastSubProjectIndex >= 0) {
                subProject.children[subProjectSize].last = true

            }
            if(subProject.children.length > 0) {
                subProject.isDir = true
                subProject.ext = null
                subProject.relativePath = null
            }
            if(subProjectIndex !== subProjects.length - 1) {
                subProject.children.forEach((child) => {
                    child.parentIsLast = false
                })
                subProject.last = false
            }
            else if(subProjectIndex === subProjects.length -1) {
                subProject.last = true
            }
        })
    })
    
    result[0].children = projects
    const real = {
        name:'projects',
        children: result
    }
    const treeData = flattenTree(real)

    //projectPath from URL and setting for sharing project urls
    let urlSelection = []
    let expandedDirs = []
    expandedDirs[0] = 1
    if(params.path !== null && params.path !== uiStore.directorySelection) {
        let projectDir
        let projectPaths = params.path.split("/")
        //get last sub path
        let projectPath = projectPaths[projectPaths.length - 1]
        projectDir = treeData.find((dir)=> {
            if(dir.name === projectPath){
                return dir
            }
            else
                return null
        })

        if (params.file !== null) {
            // find all occurences of file name because there can be duplicates scattered around every dir
            // https://stackoverflow.com/questions/20798477/how-to-find-index-of-all-occurrences-of-element-in-array/20798567
            const getAllIndexes = (arr, val) => {
                var indexes = [], i;
                for(i = 0; i < arr.length; i++)
                    if (arr[i].name === val)
                        indexes.push(i);
                return indexes;
            }
            let foundFiles = getAllIndexes(treeData, params.file)
            let projectFileId = foundFiles.find((file) => {
                if(treeData[file].parent === projectDir.id)
                    return file
                else 
                    return null
            })
            let parent = treeData[projectFileId].parent
            let grandParent = treeData[parent].parent
            //console.log(parent)
            if (grandParent > 1) {
                // expand grandparent and parent folder
                //console.log(treeData[parent].id)
                //console.log(treeData[grandParent].id)
                expandedDirs[2] = treeData[parent].id
                expandedDirs[1] = treeData[grandParent].id
            }
            else {
                expandedDirs[1] = parent
            }
            urlSelection[0] = projectFileId
            
                // expand only the parent folder
        }
        else {
            let id = projectDir.id
            let parent = treeData[id].parent
            //console.log(id)
            if(parent > 1) {
                expandedDirs[2] = treeData[parent].id
                expandedDirs[1] = projectDir.id
            }
            else {
                expandedDirs[1] = id
            }
            urlSelection[0] = projectDir.id
        }

    }

    const selectPath = ({treeState}) => {
        let id = Array.from(treeState.selectedIds)[0]
        if(typeof id !="undefined") {
            let selection = treeData[id]
            //console.log(selection)
            //if(selection.relativePath !== uiStore.fileSelection) {
                //console.log(selection)
                if(selection.isDir) {
                    //debugger
                    uiStore.Select(selection.relativeDir, null, null)
                    navigate(`?path=${selection.relativeDir}`)
                }
                else {
                    uiStore.Select(selection.relativeDir, selection.relativePath, selection.ext)
                    navigate(`?path=${selection.relativeDir}&file=${selection.name}`)
                }

            //}

        }
    }

    const focusPath = (selection) => {
        //debugger
        //console.log("FOCUS = ", selection)
        
        if(!blocked && !uiStore.mainVidPlaying) {
            if(typeof selection != "undefined" && selection !== null) {
                if(selection.isDir)
                    uiStore.Focus(selection.relativeDir, null, null)
                else
                    uiStore.Focus(selection.relativeDir, selection.relativePath, selection.ext)
                
            }
            else
                uiStore.Focus(null, null, null)

        }
    }

    let focusId

    return (
        <>
        <div>
            <div className="directory">
                <TreeView
                    onMouseLeave={() => {
                        focusPath(null)
                    }}
                    data={treeData}
                    defaultExpandedIds={expandedDirs}
                    defaultSelectedIds={urlSelection}
                    togglableSelect={true}
                    aria-label="directory tree"
                    nodeRenderer={({
                        element,
                        isBranch,
                        isExpanded,
                        getNodeProps,
                        level,
                        isSelected,
                        treeState,
                    }) => {
                        if(treeState.lastInteractedWith !== focusId) {
                            focusId = treeState.lastInteractedWith
                            let selection = treeData[focusId]
                            focusPath(selection)
                        }
                        const FileIcon = ( file ) => {
                            const ext = file.ext
                            switch(ext) {
                                case "md": case "vtt":
                                    return <><FiFileText className="icon"/></>
                                case "jpg": case "jpeg" : case "png" : case "tiff" : case "webp" :
                                        return <><FiImage className="icon"/></>
                                case "mp4": case "webm" : case "m4a" : case "mov" :
                                        return <><FiFilm className="icon"/></>
                                default:
                                    return null
                            }
                        }
                        let text = element.name
                        let whitespace = "     "
                        let padding = "    "
                        let textWithIcon
                        if(isBranch) {
                            textWithIcon = <> <FiFolder className="icon"/> {element.name}</>
                        }
                        else {
                            textWithIcon = <> <FileIcon ext={element.ext} /> {element.name}</>
                            //get extensions then return the icon
                            //console.log(element.name, "need to get another file")
                        }

                        if (element.parentIsLast) {
                            if(level < 2)
                                text = <div className="whitespace"><span className="pre-path">└─</span><span id="dir">{textWithIcon}</span></div>
                                else if(level === 2) {
                                    if(element.last && isBranch)
                                        text = <div className="whitespace">{whitespace}<span className="pre-path">└─</span><span id="dir">{textWithIcon}</span></div>
                                    else if(!element.last && isBranch)
                                        text = <div className="whitespace">{whitespace}<span className="pre-path">├─</span><span id="dir">{textWithIcon}</span></div>
                                }
                                else if(level === 3) {
                                    if(element.last)
                                        text = <div className="whitespace">{whitespace}<span className="pre-path">{padding}  └─</span>{textWithIcon}</div>
                                    else
                                        text = <div className="whitespace">{whitespace}<span className="pre-path">{padding}  ├─</span>{textWithIcon}</div>

                                }
                                else if(level === 4 && element.grandParentIsLast) {
                                    if(element.last)
                                        text = <div className="whitespace">{whitespace}<span className="pre-path">{padding}{padding}    └─</span>{textWithIcon}</div>
                                    else
                                        text = <div className="whitespace">{whitespace}<span className="pre-path">{padding}{padding}    ├─</span>{textWithIcon}</div>
                                }
                                else if(level === 4 && !element.grandParentIsLast) {
                                    if(element.last)
                                        text = <div className="whitespace">{whitespace}<span className="pre-path">│{padding}  {padding} └─</span>{textWithIcon}</div>
                                    else
                                        text = <div className="whitespace">{whitespace}<span className="pre-path">│{padding}  {padding} ├─</span>{textWithIcon}</div>
                                }
                        }
                        else {
                            if(level === 3) {
                                if(element.last)
                                    text = <div className="whitespace">{whitespace}<span className="pre-path">│{padding} └─</span>{textWithIcon}</div>
                                else
                                    text = <div className="whitespace">{whitespace}<span className="pre-path">│{padding} ├─</span>{textWithIcon}</div>
                        }
                            else if(level === 4 && element.grandParentIsLast) {
                                if(element.last)
                                    text = <div className="whitespace">{whitespace}<span className="pre-path">{padding}  │{padding} └─</span>{textWithIcon}</div>
                                else
                                    text = <div className="whitespace">{whitespace}<span className="pre-path">{padding}  │{padding} ├─</span>{textWithIcon}</div>
                            }
                            else if(level === 4 && !element.grandParentIsLast) {
                                if(element.last)
                                    text = <div className="whitespace">{whitespace}<span className="pre-path">│{padding} │{padding} └─</span>{textWithIcon}</div>
                                else
                                    text = <div className="whitespace">{whitespace}<span className="pre-path">│{padding} │{padding} ├─</span>{textWithIcon}</div>
                            }

                            //else if(element.last && !props.node.isDir)

                                //text = <div className="whitespace">{whitespace}<span className="pre-path">{padding}└─</span>{element.name}</div>
                        
                        }
                        let insert = <div role="listitem" onMouseEnter={()=> focusPath(element)}>{text}</div>
                        return(
                            <div {...getNodeProps()}>
                                {insert}
                            </div>
                        )}
                    }
                        onSelect={selectPath}

                />
            </div>
        </div>
    </>
    )
})
//prevent rerender
export default React.memo(FileTree, compare)
