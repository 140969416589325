import React, { useEffect, useRef }from 'react'
import ImageView from './imageView'
import VideoView from './videoView'
import 'mobx-react-lite/batchingForReactDom'
import { observer, inject } from "mobx-react"

const MediaWindow = inject(`uiStore`)(
    //observer(({ uiStore}, props) => {
    observer((props) => {
        const { uiStore } = props 
        switch(uiStore.ext) {
            case "jpg": case "jpeg" : case "png" : case "tiff" : case "webp" :
                return <ImageView />
            case "mp4": case "webm" : case "m4a" : case "mov" :
                return <VideoView /> 
            default:
                return null
        }
    }) 
)
export default MediaWindow
