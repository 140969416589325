import React from "react"
//import { Link } from "gatsby"
import { useMediaQuery } from 'react-responsive'
import TileManager from "../components/tilemanager"

const IndexPage = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    //const isSmallerThanUseCase = useMediaQuery({ query: '(max-width: 768px)'})
    const isSmallerThanUseWidth = useMediaQuery({ query: '(max-width: 960px)'})
    const isSmallerThanHeight = useMediaQuery({ query: '(max-height: 510px)'})
    //console.log("isSmallerThanUseWidth",isSmallerThanUseWidth)
    //console.log("isSmaller than Height", !isSmallerThanHeight)
    //if(!isSmallerThanUseWidth !isSmallerThanHeight)
    return (
        <TileManager 
            isWider={isSmallerThanUseWidth}
            isHigher={isSmallerThanHeight}
        />
    )
}

export default IndexPage
