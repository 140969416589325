import React, { useEffect, useState, useRef }from 'react'
import { observer, inject } from "mobx-react"
import { useStaticQuery, graphql } from "gatsby"
import ImagePanner from './imagePanner'

const ImageView = inject(`uiStore`)(
    observer((props) => {
        const { uiStore } = props
        const data = useStaticQuery(graphql`
            {
                allFile(filter: { extension: { regex: "/jpeg|jpg|png|webp|gif/"}}) {
                    edges {
                        node {
                            extension
                            relativePath
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 2000, quality: 90) {
                                    aspectRatio
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        `)
        let lastImage
        const { allFile } = data
        let path = null
        const { startImage, fileFocus, fileSelection, directoryFocus, directorySelection } = uiStore

        // check if file and dir is selected or focused
        // if not focused then get startImage from md nested in directory
        if(fileFocus === null || fileSelection === null) {
            if(uiStore.directoryFocus !== null && uiStore.fileFocus === null) {
                path = startImage
            }
            else if(uiStore.directorySelection !== null && fileSelection === null) {
                if (fileFocus === null) {
                    path = startImage
                }
                else if(fileFocus !== null ) {
                    path = fileFocus
                }

            }
            else if(fileSelection !== null) {
                if(directoryFocus !== directorySelection) {
                    if(fileSelection !== startImage) {
                        path = fileSelection
                    }
                    else {
                        path = startImage
                    }
                }
                else if(fileFocus === null) {
                    path = startImage
                }
            }
        }
        else {
            if(fileFocus !== null) {
                path = fileFocus
            }
            else if(fileSelection !== null) {
                path = fileSelection
            }
        }
        let img = allFile.edges.find((file, index)=> {
            if(file.node.relativePath === path){
                return file.node
            }
            else
                return null
        })
        /////////////////////////////////////////////////////////////////////////////////////
        //const PanZoomFigure = panAndZoomHoc('div')
        let image = null
        if(typeof img !="undefined" && img.node.extension !== "md" && image !== lastImage) {
            const container = document.getElementsByClassName('2')
            return <ImagePanner node={props.currentNode} container={container[0]} durationFadeIn={200} fluid={img.node.childImageSharp.fluid} />
            //return <ImagePanner node={props.currentNode} container={container[0]} durationFadeIn={200} fluid={img.node.childImageSharp.fluid} />
             //image = <Img durationFadeIn={200} fluid={img.node.childImageSharp.fluid} />
            //image = <PanZoomFigure width={200} height={200}/>

            lastImage = image
        }
        else if(typeof img === "undefined") {
            lastImage = null
            return null

        }
    })
)
export default ImageView
